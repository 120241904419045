@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Lexend";
}

:root {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 23, 24, 26;
  --background-end-rgb: 23, 24, 26;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}
